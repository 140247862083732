<template>
  <el-container class="patroladd">
      <div class="pull-right" >
          <el-upload
                   class="upload-demo"
                   ref="upload"
                    action="/pc/company-supply/"
                   :limit="1"
                    :multiple='false'
                   :file-list="fileList"

                  >
               <el-button slot="trigger" size="small" type="primary" :loading="requested" style="margin-right: 20px">上传excel</el-button>
              <!-- <div slot="tip" class="el-upload__tip">只能上传excel文件，且不超过5MB</div>-->
              <!-- <div slot="tip" class="el-upload-list__item-name">{{fileName}}</div>-->
          </el-upload> 
          <!--<span slot="footer" class="dialog-footer">-->
          <!-- <el-button @click="visible = false">取消</el-button>-->
          <!-- <el-button type="primary" @click="submitUpload()">确定</el-button>-->
          <!--</span>-->
      </div>
  </el-container>
</template>
<script>
import axios from '../../../util/api'
import { local,session } from '../../../util/util'
export default {
  data () {
    return {
      files:'',
    }
  },
     created () {

  },
  methods: {
      beforeUpload(file){
          // console.log(file,'文件');
          this.files = file;
          const extension = file.name.split('.')[1] === 'xls'
          const extension2 = file.name.split('.')[1] === 'xlsx'
          const isLt5M = file.size / 1024 / 1024 < 5
          if (!extension && !extension2) {
              this.$message.warning('上传模板只能是 xls、xlsx格式!')
              return
          }
          if (!isLt5M) {
              this.$message.warning('上传模板大小不能超过 5MB!')
              return
          }
          this.fileName = file.name;
          setTimeout(() => {
              this.submitUpload();
          },500);
          return false; // 返回false不会自动上传
      },
      success(){
          axios.post("/pc/excel/import").then(res=>{
              this.$message.success("上传成功");
          })
      },
      // 上传excel
      submitUpload() {
          //console.log('上传'+this.files.name)
          if(this.fileName == ""){
              this.$message.warning('请选择要上传的文件！')
              return false
          }
          let fileFormData = new FormData();
          fileFormData.append("code", "t_pathology_info_excel");
          fileFormData.append("description", "excel上传测试");
          //filename是键，file是值，就是要传的文件，test是要传的文件名
          fileFormData.append('files', this.files, this.fileName);
          // 执行上传excel
          let id = '';
          axios.uploadFile('/pc/excel/import', fileFormData).then(resp => {
              if (resp.data.status != 200) {
                  this.$message.error("excel上传失败，请重新上传");
              } else {
                  id = resp.data.result[0].attachmentId;
              }
          }).catch((e) => {
              // console.log(e);
              this.$message.error("excel上传失败，请重新上传");
          }).finally(() => {
              if(id) {
                  // 触发生成订单实体数据
                  this.generateBill(id);
              }
          });
      },
      // 构建实体
      generateBill(attachmentId) {
          this.http.postJson('/admin/myTest/leadingIn/' + attachmentId + '/type_one').then(resp => {
              if (resp.data.status != 200) {
                  // this.$message.error("excel上传失败，请重新上传");
              } else {
                  this.$message.success("excel上传成功！");
                  this.query();
              }
          }).catch((e) => {
              // console.log(e);
              // this.$message.error("excel上传失败，请重新上传");
          }).finally(() => {

          });
      },
  }
}
</script>
<style lang="scss" scoped>
.patroladd{
   overflow-x: hidden;
  .add{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .el-image{width: 160px; height: 160px;margin-right: 10px}
  .back_btn{background:rgba(247,248,249,1);border:1px solid rgba(217,222,225,1);}
  .patroladd-title{height: 34px;line-height: 34px;border-left: 2px solid #788287}
  .patroladd-title1{height: 34px;line-height: 34px;}
.el-checkbox{    display: flex;
    justify-content: left;
    align-items: center;}
/deep/ .el-collapse-item .el-collapse-item__content{padding-bottom: 0px;}
}

</style>
